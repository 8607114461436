import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Anchor, Button } from "../style/generic"

const Container = styled.div`
  h1 {
    text-align: center;
  }
  p {
    text-align: center;
  }
  .home {
    width: 4rem;
    margin: 2rem auto;
  }
  width: 80%;
  margin: 4rem auto auto auto;
  ul {
    width: 40%;
    margin: 0;
    font-size: 0.8rem;
    list-style: none;
    margin: auto;
    li {
      position: relative;
      display: flex;
      justify-content: space-between;
      border: 2px solid #008080;
      padding: 0.5rem 1rem;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`

//@ts-ignore
export default function Template({ pageContext: { exerciseList } }) {
  const urlSlug = (string: any) => {
    return string
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w\-]+/g, "")
      .replace(/\-\-+/g, "-")
      .replace(/^-+/, "")
      .replace(/-+$/, "")
  }
  return (
    <Layout>
      <SEO
        title={`Bodyweight Exercise Central`}
        description={`We have carefully collected the best bodyweight exercises and compiled information about them.`}
      />
      <Container>
        <h1>Bodyweight Exercise Collection</h1>
        <p>Check out our list of bodyweight exercises you can do.</p>
        <ul>
          {exerciseList.map((exercise: any) => {
            return (
              <li key={`exercise-${exercise.id}`}>
                <span>{exercise.name}</span>
                <span>
                  <Anchor href={`/exercises/${urlSlug(exercise.name)}/`}>
                    View
                  </Anchor>
                </span>
              </li>
            )
          })}
        </ul>
        <div className="home">
          <a href="/">
            <Button>Home</Button>
          </a>
        </div>
      </Container>
    </Layout>
  )
}
